import { Swal } from 'sweetalert2'

import { Card, CardContent } from "@/Components/ui/card"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/Components/ui/popover"
import { Separator } from "@/Components/ui/separator"
import { lang, navigate } from "@/Hooks/Functions"
import { ConfirmDialog } from "@/lib/mixins"
import { cn } from "@/lib/utils"
import { Icon } from "@iconify/react"
import { Link, usePage } from "@inertiajs/react"
import { memo } from "react"

export const userLinks = [
    { href: "dashboard", label: lang('My Dashboard') },
    { href: "user.profile", label: lang('My Profile') },
    { href: "designs.mine-index", label: lang('My Designs') },
    { href: "templates.mine-index", label: lang('My Templates') },
    { href: "orders.mine", label: lang('My Orders') },
    { href: "cart.mine", label: lang('My Cart') },
]

const UserPopover = ({ iconClass }) => {
    const { auth } = usePage().props;

    const isUserAdminOrManager = auth?.user?.is_admin_or_manager;

    const doLogout = async () => {
        const { isConfirmed } = await ConfirmDialog.fire({
            title: 'Confirmation required!',
            text: "Logout?",
            confirmButtonText: "Yes! Proceed",
            allowOutsideClick: () => !Swal.isLoading(),
        })
        if (isConfirmed)
            navigate(route('logout'), 'POST');
    }

    if (!auth?.user) return <></>

    return <Popover>
        <PopoverTrigger  >
            <Icon icon="carbon:user-avatar-filled" className={cn("size-10 cursor-pointer  hover:text-cyan-600 text-primary", iconClass)} />
        </PopoverTrigger>
        <PopoverContent sideOffset={8} className="p-0 rounded-md max-w-[200px] flex flex-col overflow-hidden">
            <Card className="shadow-none border-none w-full">
                <CardContent className="flex flex-col py-2 justify-start items-center gap-2 w-full">
                    {userLinks.filter(i => i.href !== route().current()).map((link) => (<div key={link.href} className="flex flex-col gap-1 items-center justify-center w-full">
                        <Link
                            className={cn("flex py-1 px-4 w-full font-semibold text-primary hover:text-cyan-600")}
                            href={link.href === 'user.profile' ? route(link.href, { ref: auth.user.referral_token, slug: auth.user.slug }) : route(link.href)}>
                            {link.label}
                        </Link>
                        <Separator className="w-full" />
                    </div>)
                    )}
                    {isUserAdminOrManager && <>
                        <Link
                            className={cn("flex py-1 px-4 w-full font-semibold text-primary hover:text-cyan-600")}
                            href={route('admin-dashboard')}>
                            {lang('Admin Dashboard')}
                        </Link>
                        <Separator className="w-full" />
                    </>}
                    <div onClick={doLogout} className="rounded-none w-full py-2 px-4 text-sm font-semibold hover:underline cursor-pointer" >
                        {lang('Logout')}
                    </div>
                </CardContent>
            </Card>
        </PopoverContent>
    </Popover>
}

export default memo(UserPopover)
